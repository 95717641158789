@import '../scss/vars';
@import '../scss/mixins';

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-large;
  background: $color-white;
  border-top: 10px solid $color-fcb-blue;
  color: $color-fcb-gray;

  @include respond-medium {
    flex-direction: row;
  }
}

.footer__copyright {
  flex: 0 0 auto;
}

.footer__meta {
  flex: 0 0 auto;
}
