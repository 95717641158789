@use "sass:math";
@import '../scss/vars';
@import '../scss/mixins';



$header-fcb-natural-width: 200px;
$header-fcb-natural-height: 60px;

$header-logo-natural-width: 230px;
$header-logo-natural-height: 50px;

$header-fcb-height: 30px;
$header-fcb-width: math.div($header-fcb-height, $header-fcb-natural-height) *
  $header-fcb-natural-width;

$header-logo-height: 30px;
$header-logo-width: math.div($header-logo-height, $header-logo-natural-height) *
  $header-logo-natural-width;

.header {
  display: flex;
  flex-direction: column;
  padding: $spacing-large;
  background: $color-white;
  border-bottom: 10px solid $color-fcb-blue;

  @include respond-large {
    align-items: center;
    flex-direction: row;
  }
}

.header__logo {
  flex: 0 0 auto;
  width: $header-logo-width;
  height: $header-logo-height;
  margin-bottom: $spacing-medium;
  background: url('../static/logo.svg') 0 0 no-repeat transparent;
  background-size: contain;
  text-indent: 150%;
  overflow: hidden;

  @include respond-large {
    margin: 0;
  }
}

.header__content {
  flex: 1 1 auto;
  display: flex;

  @include respond-large {
    margin: 0 $spacing-large;
    justify-content: flex-end;
  }
}

.header__navigation {
  display: flex;
}

.header__navigation-items {
  list-style: none;

  @include respond-large {
    display: flex;
  }
}

.header__navigation-item {
  display: inline-block;
  margin: $spacing-medium $spacing-medium 0 0;
  font-size: 1.25rem;

  @include respond-large {
    flex: 0 0 auto;
    margin: 0;
  }

  ~ .header__navigation-item {
    @include respond-large {
      margin-left: $spacing-large;
    }
  }
}

.header__navigation-link {
  font-family: $font-family-regular;
  font-weight: $font-weight-regular;
  line-height: $line-height-regular;
  text-decoration: none;
  text-transform: uppercase;
  color: $color-fcb-blue;
  cursor: pointer;

  &:hover {
    color: $color-fcb-gray;
  }

  &--active {
    @extend .header__navigation-link;
    color: $color-fcb-orange;
  }
}

.header__fcb {
  display: none;
  flex: 0 0 auto;
  width: $header-fcb-width;
  height: $header-fcb-height;
  background: url('../static/fcb.svg') 0 0 no-repeat transparent;
  background-size: contain;
  text-indent: 150%;
  overflow: hidden;

  @include respond-large {
    display: block;
  }
}
