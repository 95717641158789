$breakpoint-medium: 450px;
$breakpoint-large: 900px;

$color-fcb-blue: #31569e;
$color-fcb-orange: #f26421;
$color-fcb-gray: #666;

$color-white: #fff;
$color-black: #000;
$color-light: #fafafa;

$spacing-small: 0.5rem;
$spacing-medium: 1rem;
$spacing-large: 2rem;

$font-family-headlines: 'Dosis';
$font-family-regular: 'Dosis';

$font-weight-regular: 300;
$font-weight-bold: 700;
$font-weight-headlines: 700;

$line-height-regular: 1.5;

$max-width: 1000px;
