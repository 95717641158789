.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .header,
  > .footer {
    flex: 0 0 auto;
  }

  > .content {
    flex: 1 1 auto;
  }
}
