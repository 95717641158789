@import '../../scss/vars';
@import '../../scss/mixins';

.conflict-check__accordion {
  margin-top: 0 !important;
  margin-bottom: 1rem;

  &.styled {
    > .content {
      padding: 1rem !important;
    }
  }
}

.conflict-check__accordion-title {
  &.conflict-check__accordion-title--completed {
    color: #21ba45 !important;
  }
}

.conflict-check__accordion-switcher {
  float: right;
}

.conflict-check__section {
  font-weight: bold;
}

.conflict-check__table {
  ~ .conflict-check__table {
    margin-top: 1rem;
  }
}

.conflict-check__diff {
  background: #fffaf3 !important;
}

.conflict-check__input {
  width: 100%;
}

.conflict-check__bar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 31337;
  display: flex;
  flex-direction: column;
  margin: 0 (-1 * $spacing-medium) 1rem (-1 * $spacing-medium);
  background: $color-light;
  padding: $spacing-medium;

  @include respond-large {
    flex-direction: row;
    justify-content: space-between;
  }
}

.conflict-check__filter {
  display: flex;
}

.conflict-check__filter-label {
  flex: 0 0 auto;
  margin-right: 1rem;
}

.conflict-check__filter-items {
  flex: 1 1 auto;
}

.conflict-check__filter-item {
  ~ .conflict-check__filter-item {
    margin-left: 1rem;
  }
}

.conflict-check__actions {
  display: flex;
}

.conflict-check__action {
  flex: 0 0 auto;

  ~ .conflict-check__action {
    margin-left: 1rem;
  }
}
