* {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  -webkit-text-size-adjust: 100%;

  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,
body,
#root {
  width: 100%;
  min-height: 100%;
  min-height: stretch;
}

body {
  background: $color-light;
  font-family: $font-family-regular;
  font-weight: $font-weight-regular;
  line-height: $line-height-regular;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-headlines;
  font-weight: $font-weight-headlines;
  line-height: $line-height-regular;
}
