$fonts: (
  ('Dosis', 'dosis-extralight', '200', 'normal'),
  ('Dosis', 'dosis-light', '300', 'normal'),
  ('Dosis', 'dosis-regular', '400', 'normal'),
  ('Dosis', 'dosis-medium', '500', 'normal'),
  ('Dosis', 'dosis-semibold', '600', 'normal'),
  ('Dosis', 'dosis-bold', '700', 'normal'),
  ('Dosis', 'dosis-extrabold', '800', 'normal')
);

@each $font in $fonts {
  $family: nth($font, 1);
  $file: nth($font, 2);
  $weight: nth($font, 3);
  $style: nth($font, 4);

  @font-face {
    font-family: $family;
    src: url('../fonts/#{$file}.woff2') format('woff2'),
      url('../fonts/#{$file}.woff') format('woff');
    font-weight: #{$weight};
    font-style: #{$style};
    font-display: swap;
  }
}
